import { CircularProgress, type CircularProgressProps, Portal } from '@mui/material';
import { css, styled } from '@mui/material/styles';

const StyledLoaderContainerDiv = styled('div')<{
  hasPadding: boolean;
  // when fullSize is true loader fills the whole page
  fullSize?: boolean;
}>`
  text-align: center;

  ${({ hasPadding, theme }) =>
    hasPadding &&
    css`
      padding: ${theme.spacing(4)};
    `}
`;

const FullSizeLoaderBox = styled('div')`
  display: grid;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: absolute;
`;

export type LoaderProps = {
  noPadding?: boolean;
  fullSize?: boolean;
  'data-test'?: string;
} & CircularProgressProps;

export const Loader = (props: LoaderProps): JSX.Element => {
  const { noPadding, fullSize, 'data-test': dataTest, ...restProps } = props;

  if (fullSize) {
    return (
      <Portal container={document.getElementById('root')} data-test={dataTest}>
        <FullSizeLoaderBox>
          <CircularProgress color="primary" {...restProps} />
        </FullSizeLoaderBox>
      </Portal>
    );
  }

  return (
    <StyledLoaderContainerDiv hasPadding={!noPadding} data-test={dataTest}>
      <CircularProgress color="primary" {...restProps} />
    </StyledLoaderContainerDiv>
  );
};
