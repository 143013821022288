import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { IconButton, Box, CircularProgress } from '@mui/material';
import MuiAutocomplete from '@mui/material/Autocomplete';
import { styled } from '@mui/material/styles';
import { AutocompleteInputChangeReason } from '@mui/material/useAutocomplete/useAutocomplete';
import { useCallback, useState } from 'react';

import { DebounceField } from '../DebounceField/DebounceField';
import { TextField } from '../TextField/TextField';

import { AutoCompleteRenderOptions } from './AutoCompleteRenderOptions';
import { AutocompleteProps, RenderOptions } from './types';

const StyledAutocomplete = styled(MuiAutocomplete, { shouldForwardProp: prop => prop !== 'isReadOnly' })<{
  isReadOnly: boolean;
}>`
  .MuiInput-input {
    cursor: ${({ isReadOnly }) => (isReadOnly ? 'pointer' : 'auto')};
  }
`;

export function AutoComplete<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>({
  textFieldProps,
  textFieldReadOnly,
  loading,
  debounced = false,
  onDebounceChange = () => {},
  renderOptions,
  ...props
}: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>): JSX.Element {
  const [inputValue, setInputValue] = useState('');
  const { field, form, onInputChange, ChipProps, ...restProps } = props;
  const CommonProps = {
    ChipProps: {
      'data-test': 'chip-button',
      deleteIcon: (
        <IconButton size="large">
          <CloseIcon />
        </IconButton>
      ),
      ...ChipProps,
    },
    disabledItemsFocusable: true,
    limitTags: 1,
    multiple: undefined,
    popupIcon: <KeyboardArrowDownIcon />,
  };

  const TextInputComponent = debounced ? DebounceField : TextField;

  const handleInputChange = useCallback(
    (e, newValue: string, reason: AutocompleteInputChangeReason) => {
      if (reason === 'reset') {
        setInputValue('');
      } else {
        setInputValue(newValue);
      }
      onInputChange?.(e, newValue, reason);
    },
    [onInputChange]
  );

  const getOptionValue = useCallback(
    (
      option: T,
      getOptionValue?: RenderOptions<T>['getOptionValue'],
      getOptionLabel?: RenderOptions<T>['getOptionValue']
    ) => {
      if (getOptionValue) return getOptionValue(option);
      if (getOptionLabel) return getOptionLabel(option);

      return typeof option === 'string' ? option : '';
    },
    []
  );

  const getDataTest = useCallback((option: T, dataTest?: string | ((option: T) => string)) => {
    return typeof dataTest === 'string' ? dataTest : dataTest?.(option);
  }, []);

  const hasMultiple = restProps.multiple === true;

  return (
    // @ts-ignore
    <StyledAutocomplete<T, Multiple, DisableClearable, FreeSolo>
      {...CommonProps}
      disableCloseOnSelect={hasMultiple}
      isReadOnly={!!textFieldReadOnly}
      renderOption={(state, option, { selected }) => {
        return (
          <li data-test="autocomplete-option" {...state} key={option}>
            <AutoCompleteRenderOptions
              option={getOptionValue(option, renderOptions?.getOptionValue, restProps?.getOptionLabel)}
              childrenLocation={renderOptions?.childrenLocation}
              dataTest={getDataTest(option, renderOptions?.dataTest)}
              phraseToHighlight={inputValue}
              getPaddingLevel={() => renderOptions?.getPaddingLevel?.(option)}
              tooltip={renderOptions?.tooltip}
              tooltipPlacement={renderOptions?.tooltipPlacement}
              tooltipMessage={renderOptions?.tooltipMessage?.(option)}
              hasMultiple={hasMultiple}
              selected={selected}
            >
              {renderOptions?.renderChildren?.(option)}
            </AutoCompleteRenderOptions>
          </li>
        );
      }}
      {...(props as MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>)}
      onInputChange={handleInputChange}
      renderInput={({ InputLabelProps, InputProps, ...restParams }) => (
        // @ts-ignore
        <TextInputComponent
          onChange={onDebounceChange}
          {...restParams}
          {...textFieldProps}
          InputLabelProps={{ disableAnimation: true, focused: false, shrink: true, ...InputLabelProps }}
          InputProps={{
            disableUnderline: true,
            ...InputProps,
            endAdornment: (
              <>
                {loading && (
                  <Box pr={1}>
                    <CircularProgress size={20} />
                  </Box>
                )}
                {InputProps.endAdornment}
              </>
            ),
            readOnly: textFieldReadOnly,
          }}
        />
      )}
    />
  );
}
