import { Button } from '@mui/material';
import { ComponentProps, ReactNode, SyntheticEvent } from 'react';

export interface BaseRow {
  id?: string;
  expanded?: boolean;
  name?: string | null;
  children?: any[];
}

export type RowAction<RowT extends BaseRow> = (row: RowT, e: SyntheticEvent) => void;

export type RowActionState<RowT extends BaseRow> = ((row: RowT) => boolean) | boolean;

export interface RowActionBase<RowT extends BaseRow> {
  label: string | ((row: RowT) => string);
  onClick: RowAction<RowT>;
  allowed?: RowActionState<RowT>;
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | 'default';
  disabled?: RowActionState<RowT>;
  loading?: RowActionState<RowT>;
  hide?: RowActionState<RowT>;
  href?: string | ((row: RowT) => string);
  notAllowedMessage?: string | ((row: RowT) => string);
  'data-test'?: string | ((row: RowT) => string);
  variant?: ComponentProps<typeof Button>['variant'];
}

export interface RowActionButtonBaseProps<RowT extends BaseRow> extends RowActionBase<RowT> {
  notAllowedMessage?: string | ((row: RowT) => string);
}

export interface RowActionProps<RowT extends BaseRow> extends RowActionBase<RowT> {
  topDivider?: boolean;
  error?: boolean;
  danger?: boolean;
}

export type Highlighter = (content: string) => ReactNode;

export interface TableColumn<RowT extends BaseRow> {
  title: ReactNode;
  tooltipMessage?: string;
  key: string;
  render?: (row: RowT, highlighter: Highlighter) => ReactNode;
  width?: string;
  calcWidth?: string;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  multivalue?: boolean;
  multivalueTooltip?: boolean;
  mapBy?: { [id: string]: { name: string } | undefined };
  ellipsis?: boolean;
  ellipsisMaxWidth?: string;
  isDate?: boolean;
  hide?: boolean;
  disabled?: boolean;
  isExactDate?: boolean;
  isDateTime?: boolean;
  dataTest?: string;
}

export type Columns<RowT extends BaseRow> = TableColumn<RowT>[];
export const expandContentRowClass = 'expandContentRow';
export const expandContentCellClass = 'expandContentCell';
