import { CssBaseline, GlobalStyles, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { SnackbarOrigin, SnackbarProvider as ISnackbarProvider } from 'notistack';
import { useEffect, useRef } from 'react';

import { SiteUnavailablePopup } from '@openx/components/core/lib/SiteUnavailablePopup/SiteUnavailablePopup';
import { CloseNotificationButton } from '@openx/components/core/lib/Snackbar/CloseNotificationButton';
import { SnackbarProvider } from '@openx/components/core/lib/Snackbar/SnackbarProvider';
import { theme } from '@openx/components/core/lib/theme/theme';
import { globalStyles } from '@openx/constants/styles';

import { CTA_VERSION } from 'config';
import { useRollbar } from 'modules/analytics/rollbar';
import { Dashboard } from 'modules/dashboard';

const snackBarProps: SnackbarOrigin = { horizontal: 'left', vertical: 'bottom' };

export const App = () => {
  const { initialize } = useRollbar();

  const notistackRef = useRef<ISnackbarProvider>(null);

  useEffect(() => {
    console.info(`%cCTA version: ${CTA_VERSION}`, 'font-weight:bold;');
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const onClickDismiss = key => () => {
    notistackRef.current && notistackRef.current.closeSnackbar(key);
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          ref={notistackRef}
          anchorOrigin={snackBarProps}
          action={key => <CloseNotificationButton action={onClickDismiss(key)} />}
        >
          <GlobalStyles styles={globalStyles} />
          <CssBaseline />
          <SiteUnavailablePopup />
          <Dashboard />
        </SnackbarProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
