import { Collapse, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Button } from '@openx/components/core/lib/Button/Button';

import { Fields } from 'modules/landingPage/types';

import { CSVDropzone } from './components';

const StyledContentTypography = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing(1.5)};
`;

const StyledDownloadTemplateButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing(1.5)};
`;

const StyledDetailsToggleButton = styled(Button)`
  font-size: 10px;
`;

const StyledHeaderContainerDiv = styled('div')`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

interface Props {
  detailsVisible: boolean;
  fieldName: Fields.BUNDLES | Fields.DOMAINS;
  onDetailsToggle: () => void;
  resourceName: string;
  templateContent: string;
  title: string;
}

export const CSVSection = ({
  detailsVisible,
  fieldName,
  onDetailsToggle,
  resourceName,
  templateContent,
  title,
}: Props) => {
  const { t } = useTranslation();

  const templateFileName = t('{resourceName}s-template', { resourceName }) + '.csv';
  const templateDataUrl = URL.createObjectURL(
    new Blob([templateContent], {
      type: 'text/csv',
    })
  );

  return (
    <>
      <StyledHeaderContainerDiv>
        <Typography variant="h3" data-test="upload-title">
          {title}
        </Typography>
        <StyledDetailsToggleButton variant="text" color="primary" onClick={onDetailsToggle} data-test="details-button">
          {detailsVisible ? t('Hide') : t('Show')} {t('details')}
        </StyledDetailsToggleButton>
      </StyledHeaderContainerDiv>
      <Collapse in={detailsVisible}>
        <StyledContentTypography data-test="details-text">
          {t(
            'Please download the following template to upload your {resourceName}s. The resulting files should include no more than 1 column and each new {resourceName} should be on a new row.',
            { resourceName }
          )}
        </StyledContentTypography>
        <a download={templateFileName} href={templateDataUrl}>
          <StyledDownloadTemplateButton size="small" data-test="download-template">
            {t('Download template')}
          </StyledDownloadTemplateButton>
        </a>
      </Collapse>
      <CSVDropzone fieldName={fieldName} />
    </>
  );
};
