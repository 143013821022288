import { styled } from '@mui/material';
import i18n from 'i18next';
import { useEffect } from 'react';

import { Error, type ErrorProps } from '@openx/components/core/lib/Error/Error';
import { NotFoundImage } from '@openx/components/core/lib/Error/pictures/NotFound';
import { ErrorBoundary } from '@openx/components/core/lib/ErrorBoundary/ErrorBoundary';
import { Loader } from '@openx/components/core/lib/Loader/Loader';
import { Logo } from '@openx/components/core/lib/Logo/Logo';
import { TOP_BAR_HEIGHT } from '@openx/constants/styles';

import { useContractFetch } from 'api/hooks';
import { Result, sentGoogleResult, useGoogleIntegration } from 'modules/googleIntegration';
import { LandingPage } from 'modules/landingPage';
import { CtaProvider } from 'state/ctaProvider';

import { LanguagePicker } from './LanguagePicker';

const StyledLogoContainerDiv = styled('div')`
  display: flex;
  width: 170px;
`;

const StyledContainerHeader = styled('header')`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.paper};
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  display: flex;
  height: ${TOP_BAR_HEIGHT}px;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing(0, 4)};
  z-index: 2;
`;

export function Dashboard(): JSX.Element {
  const { error, pending, data } = useGoogleIntegration();
  const pubId = data?.['publisher-id'];

  const { isContractLoading, contractError, contract, fetchContract } = useContractFetch();

  useEffect(() => {
    pubId && fetchContract(pubId);
  }, [fetchContract, pubId]);

  const errorProps: ErrorProps = {
    PictureComponent: NotFoundImage,
    actions: [
      {
        children: i18n.t('Back to google'),
        color: 'secondary',
        'data-test': 'button-back-to-google',
        onClick: () => {
          sentGoogleResult(Result.ERROR);
        },
        variant: 'contained',
      },
    ],
    subtitle: i18n.t("We aren't able to process your request. Please try again"),
    title: i18n.t('Something went wrong'),
  };

  if (error || contractError) {
    return (
      <Error
        title={i18n.t('Something went wrong')}
        subtitle={i18n.t("We aren't able to process your request. Please try again")}
        PictureComponent={NotFoundImage}
        data-test="error-on-google-integration"
      />
    );
  }

  if (pending || isContractLoading) return <Loader />;

  return (
    <ErrorBoundary errorProps={errorProps}>
      <CtaProvider googleData={data} alreadySignedPublisher={contract}>
        <StyledContainerHeader>
          <StyledLogoContainerDiv>
            <Logo darkMode data-test="logo" />
          </StyledLogoContainerDiv>
          <LanguagePicker />
        </StyledContainerHeader>
        <LandingPage />
      </CtaProvider>
    </ErrorBoundary>
  );
}
