import { Typography, Grid, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Button } from '@openx/components/core/lib/Button/Button';

const StyledDownloadPDFButton = styled(Button)`
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

const StyledIframe = styled('iframe')`
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  margin-top: ${({ theme }) => theme.spacing(1)};
`;

export function ContractSigningSection(): JSX.Element {
  const { t } = useTranslation();

  // TODO UIG-540
  // LINK JAPANESE PDF
  return (
    <Grid>
      <Typography variant="h3" gutterBottom data-test="terms-and-conditions-title">
        {t('OpenX Terms & Conditions')}
      </Typography>
      <StyledIframe
        title="terms&conditions"
        src="/assets/terms_and_conditions.pdf"
        width="100%"
        height="500px"
        data-test="iframe-pdf"
      />
      <a
        href="/assets/terms_and_conditions.pdf"
        target="_blank"
        rel="noopener noreferrer"
        download={t('OpenX Terms&Conditions')}
      >
        <StyledDownloadPDFButton data-test="download-pdf">{t('Download as PDF')}</StyledDownloadPDFButton>
      </a>
    </Grid>
  );
}
