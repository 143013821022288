import { useMemo } from 'react';

import { Paper } from '@openx/components/core/lib/Paper/Paper';

import { makeActionSection } from './ActionSection';

interface ActionPaperProps {
  children: Function;
}

export function ActionPaper({ children }: ActionPaperProps): JSX.Element {
  const ActionSection = useMemo(() => makeActionSection(), []);

  return <Paper>{props => <>{children({ ...props, ActionSection })}</>}</Paper>;
}
