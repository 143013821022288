import type { RefObject } from 'react';
import { DebounceInput, type DebounceInputProps } from 'react-debounce-input';

import { TextField, type TextFieldProps } from '../TextField/TextField';

export const DebounceField = (props: DebounceInputProps<HTMLInputElement, TextFieldProps>) => (
  <DebounceInput
    {...props}
    debounceTimeout={props.debounceTimeout || 300}
    element={TextField}
    minLength={0}
    ref={props.ref as string & RefObject<HTMLInputElement>}
  />
);
