import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Typography } from '@mui/material';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { parse } from 'query-string';
import { useCallback, useState, useRef, useMemo, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { RHFAutoComplete } from '@openx/components/core/lib/AutoComplete/RHFAutoComplete';
import { Button } from '@openx/components/core/lib/Button/Button';
import { RHFCheckboxField } from '@openx/components/core/lib/CheckboxField/RHFCheckboxField';
import { RHFTextField } from '@openx/components/core/lib/TextField/RHFTextField';
import { TextField } from '@openx/components/core/lib/TextField/TextField';
import { trackPageView } from '@openx/utils/lib';

import { ActionPaper } from 'components/ActionPaper';
import { useCta } from 'state/ctaProvider';

import { originProductToAccountTypeMap } from '../constants';
import { AccountType, Details, Fields } from '../types';
import { buildRequestPayload } from '../utils';

import { getCSVTemplates } from './constants';
import { ContractSigningSection, CSVSection } from './sections';
import { getValidationSchema } from './validator';

const detailsInitialValues: Details = {
  [Fields.LEGAL_NAME]: '',
  [Fields.WEBSITE]: '',
  [Fields.EMAILS]: [],
  [Fields.DOMAINS]: null,
  [Fields.BUNDLES]: null,
  [Fields.CONTRACT_SIGN_IN]: false,
};

const testDetailsInitialValues: Details = {
  ...detailsInitialValues,
  [Fields.LEGAL_NAME]: 'OpenxTest',
  [Fields.WEBSITE]: 'openx-test.com',
  [Fields.EMAILS]: ['test1@openx.com', 'test2@openx.com'],
  // Uncomment for development
  // [Fields.DOMAINS]: new Blob(['test@test.pl'], { type: 'text/csv' }) as any,
  // [Fields.CONTRACT_SIGN_IN]: true,
};

export function DetailsStep(): JSX.Element {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { i18n, t } = useTranslation();
  const snackbarKey = useRef<string | number>(0);

  const initValues = 'fill' in parse(window.location.search) ? testDetailsInitialValues : detailsInitialValues;
  const {
    state: { details, googleData },
    handleNextStep,
    setDetails,
  } = useCta();

  useEffect(() => {
    trackPageView(window.location.href + window.location.search);
  }, []);

  if (!googleData) {
    throw new Error(t('Unknown google data'));
  }

  const formMethods = useForm<Details>({
    defaultValues: details || initValues,
    mode: 'all',
    resolver: yupResolver(getValidationSchema(originProductToAccountTypeMap[googleData['origin-product']], t)),
  });

  const [uploadsDetailsVisibility, setUploadsDetailsVisibility] = useState({
    bundles: false,
    domains: false,
  });

  const {
    setError,
    formState: { errors },
    trigger,
  } = formMethods;

  const onSubmit = useCallback(
    async (details: Details): Promise<void> => {
      const informationInterval = setTimeout(() => {
        snackbarKey.current = enqueueSnackbar(
          t('Your request is being validated. It might take up to a few minutes...'),
          {
            persist: true,
            variant: 'info',
          }
        );
      }, 500);

      try {
        const payload = buildRequestPayload({ details, googleData });

        await axios.post('/api/v1/contract', payload);

        setDetails(details);
        handleNextStep();
      } catch (error) {
        const errors = error?.response?.data?.field_errors || {};
        const errorMessages = Object.values(errors);

        if (!errorMessages.length) {
          enqueueSnackbar(t('Something went wrong. Try again or refresh the page.'), {
            persist: true,
            variant: 'error',
          });
          return;
        }

        Object.keys(errors).forEach(fieldName => {
          setError(fieldName as Fields, { message: errors[fieldName] });
        });

        const snackError = Array.isArray(errorMessages[0])
          ? Object.values(errorMessages[0])[0]
          : JSON.stringify(errorMessages[0]);
        enqueueSnackbar(snackError as string, { persist: true, variant: 'error' });
      } finally {
        clearTimeout(informationInterval);
        closeSnackbar(snackbarKey.current);
      }
    },
    [closeSnackbar, enqueueSnackbar, googleData, handleNextStep, setDetails, t, setError]
  );

  const onDomainsDetailsToggle = useCallback(() => {
    setUploadsDetailsVisibility({
      ...uploadsDetailsVisibility,
      domains: !uploadsDetailsVisibility.domains,
    });
  }, [uploadsDetailsVisibility]);

  const onBundlesDetailsToggle = useCallback(() => {
    setUploadsDetailsVisibility({
      ...uploadsDetailsVisibility,
      bundles: !uploadsDetailsVisibility.bundles,
    });
  }, [uploadsDetailsVisibility]);

  const domainsUploadVisible = originProductToAccountTypeMap[googleData['origin-product']] !== AccountType.ADMOB;
  const CSVTemplates = useMemo(() => getCSVTemplates(), []);

  const handleLanguageChange = useCallback(() => {
    // this is a hack to trigger revalidation in the end
    setTimeout(() => {
      trigger(Object.keys(errors) as Fields[]);
    }, 0);
  }, [errors, trigger]);

  useEffect(() => {
    i18n.on('languageChanged', handleLanguageChange);

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [handleLanguageChange, i18n]);

  return (
    <ActionPaper>
      {({ ActionSection }) => (
        <>
          <Typography variant="h2" gutterBottom data-test="details-title">
            {t('Your Details')}
          </Typography>

          <FormProvider {...formMethods}>
            <form data-test="cta-form" autoComplete="off" noValidate onSubmit={formMethods.handleSubmit(onSubmit)}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <RHFTextField
                    name={Fields.LEGAL_NAME}
                    label={t('Legal Company Name')}
                    placeholder={t('Type your company name')}
                    fullWidth
                    margin="dense"
                    data-test="legal-company-name"
                  />
                  <RHFTextField
                    name={Fields.WEBSITE}
                    label={t('Company Website')}
                    placeholder={t('Type your company website address')}
                    fullWidth
                    margin="dense"
                    data-test="company-website"
                  />
                  <RHFAutoComplete
                    name={Fields.EMAILS}
                    textFieldProps={{
                      label: t('Email'),
                      margin: 'dense',
                      placeholder: t(
                        'Type your email for our contact records (you can enter multiple addresses by enter)'
                      ),
                    }}
                    fullWidth
                    freeSolo
                    options={[]}
                    multiple
                    limitTags={3}
                    disableClearable
                    data-test="emails"
                    autoSelect
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h3" gutterBottom data-test="inventory-details-title">
                    {t('Inventory details')}
                  </Typography>
                  <TextField
                    label={t('Google Account Type')}
                    margin="dense"
                    data-test="google-account-type"
                    value={originProductToAccountTypeMap[googleData?.['origin-product']]}
                    disabled
                    fullWidth
                  />
                  {!!googleData?.['network-code'] && (
                    <TextField
                      label={t('Google Network Code')}
                      margin="dense"
                      data-test="google-network-code"
                      value={googleData?.['network-code']}
                      disabled
                      fullWidth
                    />
                  )}
                  <TextField
                    label={t('Google Publisher ID')}
                    margin="dense"
                    data-test="google-publisher-id"
                    value={googleData?.['publisher-id']}
                    disabled
                    fullWidth
                  />
                </Grid>
                {domainsUploadVisible && (
                  <Grid item xs={12}>
                    <CSVSection
                      detailsVisible={uploadsDetailsVisibility.domains}
                      fieldName={Fields.DOMAINS}
                      onDetailsToggle={onDomainsDetailsToggle}
                      resourceName={t('domain')}
                      templateContent={CSVTemplates.domains}
                      title={t('Upload domains')}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <CSVSection
                    detailsVisible={uploadsDetailsVisibility.bundles}
                    fieldName={Fields.BUNDLES}
                    onDetailsToggle={onBundlesDetailsToggle}
                    resourceName={t('bundle')}
                    templateContent={CSVTemplates.bundles}
                    title={t('Upload bundles')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ContractSigningSection />
                  <RHFCheckboxField
                    name={Fields.CONTRACT_SIGN_IN}
                    label={t(
                      'I agree that I have reviewed the OB Service Terms and that I am authorized to sign these terms on behalf of my company.'
                    )}
                    data-test="contract-sign-consent"
                  />
                </Grid>
              </Grid>
              <ActionSection>
                <Grid container justifyContent="flex-end" alignItems="center">
                  <Grid item>
                    <Button
                      type="submit"
                      color="primary"
                      loading={formMethods.formState.isSubmitting}
                      data-test="next-button"
                    >
                      {t('Submit')}
                    </Button>
                  </Grid>
                </Grid>
              </ActionSection>
            </form>
          </FormProvider>
        </>
      )}
    </ActionPaper>
  );
}
