import { CloudUpload, Description } from '@mui/icons-material';
import { FormHelperText, Theme, Typography } from '@mui/material';
import { css, styled } from '@mui/material/styles';
import { MouseEvent, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { humanFileSize } from '@openx/utils/lib/humanFileSize';

import { Button } from '../Button/Button';

const sharedIconStyles = (theme: Theme) => css`
  font-size: 2em;
  margin-right: ${theme.spacing(1)};
`;

const StyledUploadHintsDiv = styled('div')`
  align-items: center;
  display: flex;

  & > p {
    align-items: center;
    display: inline-flex;
  }

  & > span {
    margin: ${({ theme }) => theme.spacing(0, 2)};
  }
`;

const StyledCloudUploadIcon = styled(CloudUpload)`
  ${({ theme }) => sharedIconStyles(theme)}
`;

const StyledDescriptionIcon = styled(Description)`
  ${({ theme }) => sharedIconStyles(theme)}
`;

const StyledFileInfoDiv = styled('div')`
  align-items: center;
  display: flex;

  & > p {
    margin-right: ${({ theme }) => theme.spacing(1.2)};
  }
`;

const StyledWrapperDiv = styled('div')`
  margin-top: ${({ theme }) => theme.spacing(1.5)};
`;

const StyleDropzoneDiv = styled('div')<{ disabled: boolean; hasFile: boolean }>`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.boxLight};
  border: 1px solid ${({ theme }) => theme.palette.background.grayLight};
  border-radius: 2px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  display: flex;
  height: 82px;
  justify-content: center;
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  &:focus {
    outline: none;
  }

  ${({ hasFile, theme }) =>
    hasFile &&
    css`
      justify-content: space-between;
      padding: ${theme.spacing(0, 2.2)};
    `}
`;

interface DropzoneProps {
  accept?: string | string[];
  disabled?: boolean;
  file?: File;
  onDropFile: (file: File) => void;
  onCancel: () => void;
  className?: string;
  errorMessage?: string;
  helpMessage?: string;
}

export const Dropzone = ({
  accept,
  disabled = false,
  onDropFile,
  onCancel,
  file,
  className,
  errorMessage,
  helpMessage,
}: DropzoneProps): JSX.Element => {
  const { t } = useTranslation();

  const onDrop = useCallback(
    (uploadedFiles: File[]) => {
      onDropFile(uploadedFiles[0]);
    },
    [onDropFile]
  );

  const { getRootProps, getInputProps } = useDropzone({ accept, disabled, multiple: false, onDrop });

  const onCancelCallback = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      onCancel();
    },
    [onCancel]
  );

  const renderContent = (): JSX.Element => {
    if (!file) {
      return (
        <StyledUploadHintsDiv>
          <StyledCloudUploadIcon color="primary" data-test="upload-icon" />
          <Typography variant="caption" component="p" data-test="drag-n-drop-text">
            {t('DROP FILE HERE')}
          </Typography>
          <span>{t('or')}</span>
          <Button data-test="browse-button" size="small">
            {t('Browse')}
          </Button>
        </StyledUploadHintsDiv>
      );
    }

    return (
      <>
        <StyledFileInfoDiv>
          <StyledDescriptionIcon color="primary" data-test="description-icon" />
          <Typography variant="body1" color="textSecondary">
            {humanFileSize(file.size)}
          </Typography>
          <Typography variant="body1">{file.name}</Typography>
        </StyledFileInfoDiv>
        <Button variant="text" color="error" onClick={onCancelCallback} data-test="remove-button">
          {t('REMOVE')}
        </Button>
      </>
    );
  };

  return (
    <StyledWrapperDiv className={className}>
      <StyleDropzoneDiv hasFile={!!file} disabled={disabled} {...getRootProps()} data-test="drop-zone">
        <input data-test="file-input" {...getInputProps()} />
        {renderContent()}
      </StyleDropzoneDiv>

      {helpMessage && !errorMessage && <FormHelperText>{helpMessage}</FormHelperText>}
      {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
    </StyledWrapperDiv>
  );
};
