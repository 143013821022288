import CloseIcon from '@mui/icons-material/Close';
import { DialogTitle as MuiDialogTitle, IconButton, Stack } from '@mui/material';
import type { ReactNode, SyntheticEvent } from 'react';

export type DialogTitleProps = {
  children?: ReactNode;
  dataTest?: string;
  onClose?: (e: SyntheticEvent<HTMLElement>) => void;
  onCloseDisabled?: boolean;
};

export const DialogTitle = ({
  children,
  dataTest = 'modal-title',
  onClose,
  onCloseDisabled = false,
}: DialogTitleProps) => (
  <Stack alignItems="center" data-test={dataTest} direction="row" justifyContent="space-between">
    <MuiDialogTitle>{children}</MuiDialogTitle>

    {onClose && (
      <IconButton aria-label="Close dialog" disabled={onCloseDisabled} onClick={onClose} size="large" sx={{ mr: 2 }}>
        <CloseIcon data-test="close-dialog" />
      </IconButton>
    )}
  </Stack>
);
